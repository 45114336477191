class ZIDXAccountUserInvite implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountUserInviteContainer";
    }
    getMatchURL(){
        return "/account/users/invite";
    }
    render(){
        // console.log("invite");
    }
}